html {
    ::-webkit-scrollbar {
        display: none;
    }
      
      /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

body {
    background-color: #0B2027;
    color: #e3ebf1;
    a {
        color: rgb(138, 203, 213);
    }
}

.nav-bar {
    display: flex;
    flex-direction: row;
    margin: 20px auto;
    width: 70%;
    justify-content: space-between;
    align-items: center;
    .image-name {
        display: flex;
        align-items: center;
        gap: 15px;
        img.kettle {
            width: 50px;
            border-radius: 10px;
        }
        .name {
            font-size: 24px;
        }
    }
}

.nav-buttons {
    display: flex;
    align-items: center;
    button {
        background: none;
        border: none;
        font-family: "Georgia";
        font-size: 24px;
        cursor: pointer;
    }
}

@media (max-width: 767px) {
    .nav-bar {
        flex-direction: column;
        gap: 15px;
    }
    .cv {
        font-size: 10px;
        margin: 40px 20px;
    }
}

.about {
    margin: 40px auto;
    max-width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    .headshot-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;
        .headshot {
            width: 200px;
            border-radius: 20px;
        }
        .headshot-caption {
            font-size: 10px;
        }
    }
    p {
        margin: 0;
    }
}

.gallery {
    .contact {
        width: fit-content;
        margin: auto;
        margin-bottom: 40px;
    }
    .year-nav {
        margin: 40px auto 50px;
        justify-content: space-evenly;
        width: 80%;
        flex-wrap: wrap;
    }
    .gallery-year-container {
        margin: auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 10px; 
        row-gap: 40px;
        max-width: 70vw; 
        margin-bottom: 30px;
        .image-container {
            height: 300px;
            width: 300px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            margin-bottom: 8px;
            .work-primary-image {
                max-width: 100%;
                max-height: 100%;
                opacity: 0;
                transition: opacity 1s ease-in-out;
                &.fade-in {
                    opacity: 1
                }
                cursor: pointer;
            }
        }
        .caption {
            font-style: italic;
            text-align: center;
            opacity: 0;
            transition: opacity 1s ease-in-out;
            &.fade-in {
                opacity: 1
            }
        }
    }

    .gallery-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 10;
        background-color: black;
        opacity: .9;
    }
    .focused-image-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        z-index: 15;
        overflow: hidden;

        .focused-image-row {
            display: flex;
            align-items: center;
            gap: 15px;
            justify-content: space-between;
            margin: 0px 25px;
            height: 85%;
        }
        .focused-image {
            max-width: 90%;
            max-height: 90%;
        }
        .forward, .back {
            font-size: 30px;
            cursor: pointer;
        }
        .close {
            position: absolute;
            top: 0px;
            right: 20px;
            font-size: 40px;
            font-family: 'Arial';
            cursor: pointer;
        }
        .caption {
            font-style: italic;
            text-align: center;
        }
        .preview-row {
            display: flex;
            gap: 10px;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 15px;
            .image-preview {
                max-width: 60px;
                max-height: 60px;
                border-radius: 2px;
                cursor: pointer;
                &.selected {
                    outline: 2px solid rgb(70, 198, 240);
                }
            }
        }
       
    }
}

.cv {
    max-width: 700px;
    margin: 40px auto;
    font-size: 14px;

    section {
        margin-bottom: 30px;
    }

    h2 {
        margin: 16px 0px 4px;
    }

    h3 {
        display: flex;
        margin: 8px 0px;
        width: 100%;
        justify-content: space-between;
    }

    p,
    ul {
        margin: 4px 0px;
    }
}

@media (max-width: 767px) {
    .about {
        margin: 40px 20px;
    }
    .cv {
        font-size: 10px;
        margin: 40px 20px;
    }
}